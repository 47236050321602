import React, {useEffect, useState} from "react";
import {API, graphqlOperation} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import '../pages/login.css';
import {myDataType} from "../../API";
import {getMyData} from "../../graphql/queries";
import "@fontsource/poppins";
import {usePrevious} from "react-use";

export interface GenericTemplateProps {
    loginId: any;
    login_user: any;
}

const LoginHeader: React.FC<GenericTemplateProps> = ({loginId, login_user}) => {

    const [loaded, setLoaded] = useState(false);
    const [myData, setMyData] = useState<[myDataType] | []>([]);
    // 無限ループ対策で取得した情報が更新された際に古いデータと比較する目的
    const old_data = usePrevious(myData);
    // 初回に送信するため
    const [count, setCount] = useState(false);

    useEffect(() => {
        const currentUserExec = async () => {
            try {
                const response = await API.graphql(graphqlOperation(getMyData));
                // @ts-ignore
                setMyData(response.data.getMyData.data.userGroup);
                setLoaded(true);
            } catch (e) {
                console.log(e);
            }
        };
        currentUserExec();
    }, [loginId]);

    if (loaded){
        const user_info = Object.values(myData).map(user_info => user_info)
        const user_name = user_info[1]
        // 古いデータと取得データが異なる時(変更あり)
        if (old_data !== myData) {
            login_user(user_info)
            setCount(true);
            // 古いデータと取得データが同じ時(変更なし)
        } else {
            // 初回は送信する
            if (!count) {
                login_user(user_info)
                setCount(true);
            }
        }

        return (
            <div style={{ overflow: 'hidden', paddingRight: '58px' }}>
                {/*
                <div>
                    <p style={{ textAlign: 'right', fontFamily: 'Noto Sans CJK JP', fontSize: '20px', margin: 0, whiteSpace: "nowrap" }}>
                        <img src={`${process.env.PUBLIC_URL}/static/triangle.png`} alt="arrow" style={{ width: "17px", height: "20px", marginRight: "20px" }}/>{ user_name }
                    </p>
                </div>
                */}
                <div>
                    <p style={{ textAlign: 'right', fontSize: '20px', margin: 0, paddingTop: "5px", whiteSpace: "nowrap" }}>ID { loginId }</p>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ overflow: 'hidden', paddingRight: '58px' }}>
                <div>
                    <p style={{ textAlign: 'right', fontFamily: 'Noto Sans CJK JP', fontSize: '20px', margin: 0, whiteSpace: "nowrap" }}>
                        <img src={`${process.env.PUBLIC_URL}/static/triangle.png`} alt="arrow" style={{ width: "17px", height: "20px", marginRight: "20px" }} />loading...
                    </p>
                </div>
                <div>
                    <p style={{ textAlign: 'right', fontSize: '20px', margin: 0, paddingTop: "5px", whiteSpace: "nowrap" }}>ID loading...</p>
                </div>
            </div>
        );
    }
};

export default LoginHeader;
